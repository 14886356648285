import styled from "styled-components";
import * as palette from "../../assets/Colors";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  position: relative;
  height: 5rem;
  background-color: ${palette.Pearl};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: all 400ms ease-out;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  opacity: 0;
`;

export const Image = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: right;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  grid-gap: 1rem;
  align-items: center;
`;
