import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Search from "./routes/search/Search";
import Billing from "./routes/billing/Billing";
import secureLocalStorage from "react-secure-storage";
import "./App.css";
import {
	SELECTED_BANK,
	SELECTED_COUNTRY,
	SELECTED_STATE,
	SELECTED_CITY,
	SELECTED_DOCUMENT_TYPE,
	SELECTED_USER_TYPE,
} from "./assets/STORAGE_KEYS";

function App() {
	useEffect(() => {
		secureLocalStorage.clear(
			SELECTED_COUNTRY,
			SELECTED_STATE,
			SELECTED_CITY,
			SELECTED_DOCUMENT_TYPE,
			SELECTED_USER_TYPE,
			SELECTED_BANK
		);
		/*secureLocalStorage.setItem(SELECTED_COUNTRY, {
			value: 48,
			label: "Colombia",
		});*/
	});
	return (
		<Routes>
			<Route path="/" element={<Search />} />
			<Route path="/billing" element={<Billing />} />
		</Routes>
	);
}

export default App;
