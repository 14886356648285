import React from "react";
import Franchise from "../../assets/Franchise";
import Icon from "../../assets/Icon";
import * as palette from "../../assets/Colors";
import {
	InputDefault,
	InputSearch,
	InputCard,
	Wrapper,
	WrapperIcon,
	WrapperIsValid,
	WrapperFranchise,
} from "./styles";

const Input = ({
	type,
	id,
	name,
	placeholder,
	onChange,
	isValid,
	autoFocus,
	autoComplete,
	value,
	input,
	width,
	franchise,
	isLuhnChecked,
	isFullInput,
	onValidation,
}) => {
	switch (input) {
		case "search":
			return (
				<Wrapper>
					<InputSearch
						type="search"
						id={id}
						name={name}
						placeholder={placeholder}
						onChange={onChange}
						autoFocus={true}
					/>
					<WrapperIcon>
						<Icon
							name="Lens"
							color={isValid ? palette.Mint : palette.Blueprint}
							size="24"
						/>
					</WrapperIcon>
				</Wrapper>
			);
		case "card":
			return (
				<Wrapper style={{ width: width }}>
					<WrapperFranchise>
						<Franchise franchise={franchise} />
					</WrapperFranchise>
					<WrapperIsValid
						style={{
							opacity: isFullInput ? 1 : 0,
							right: isFullInput ? 0 : "3rem",
						}}
					>
						<Icon
							name={isLuhnChecked && franchise ? "Check" : "Cancel"}
							color={
								isLuhnChecked && franchise ? palette.Zima : palette.Scarlet
							}
							size="24"
						/>
					</WrapperIsValid>
					<InputCard
						style={{
							paddingRight: isFullInput ? "2.5rem" : "0.75rem",
							background: !isFullInput
								? "#fff"
								: isLuhnChecked && franchise
								? "rgba(217, 237, 255, 1)"
								: "rgba(255, 214, 219, 1)",
						}}
						id={id}
						name={name}
						placeholder={placeholder}
						type="text"
						onChange={onChange}
						autoFocus={autoFocus}
						value={value}
					/>
				</Wrapper>
			);
		case "secured":
			return (
				<Wrapper style={{ width: width }}>
					<WrapperIsValid
						style={{
							opacity: isValid ? 1 : 0,
							right: isValid ? 0 : "3rem",
						}}
					>
						<Icon name="Check" color={palette.Zima} size="24" />
					</WrapperIsValid>
					<InputDefault
						style={{
							paddingRight: isValid ? "2.5rem" : "0.75rem",
							background: isValid ? "rgba(217, 237, 255, 1)" : "#FFF",
						}}
						id={id}
						name={name}
						placeholder={placeholder}
						type="password"
						onChange={onChange}
						autoFocus={autoFocus}
						autoComplete={autoComplete}
						value={value}
					/>
				</Wrapper>
			);
		default:
			return (
				<Wrapper style={{ cursor: "pointer" }}>
					<WrapperIsValid
						style={{
							opacity: isValid ? 1 : 0,
							right: isValid ? 0 : "3rem",
						}}
					>
						<Icon name="Check" color={palette.Zima} size="24" />
					</WrapperIsValid>
					<InputDefault
						style={{
							paddingRight: isValid ? "2.5rem" : "0.75rem",
							background: isValid ? "rgba(217, 237, 255, 1)" : "#FFF",
							cursor: "pointer",
						}}
						id={id}
						name={name}
						placeholder={placeholder}
						type={type}
						onChange={onChange}
						autoFocus={autoFocus}
						autoComplete={autoComplete}
						value={value}
					/>
				</Wrapper>
			);
	}
};

export default Input;
