import styled from "styled-components";
import * as palette from "../../assets/Colors";

export const ContainerElement = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 8rem;
	border-radius: 8px;
	cursor: pointer;
	background: #f8f8f8;
	overflow: hidden;
	transition: all 200ms ease-out;
	&:hover {
		transform: translate(0, -0.25rem);
		box-shadow: 0px 0px 16px 0px ${palette.Pearl};
		background: ${palette.Pearl};
	}
`;

export const WrapperTop = styled.div`
	display: flex;
	flex-grow: 1;
	padding: 1rem;
	justify-content: space-between;
	align-items: flex-start;
`;

export const WrapperBottom = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	grid-gap: 1rem;
	padding: 0 1rem 1rem;
`;

export const ContainerCategory = styled.div`
	display: flex;
	padding: 1rem;
	grid-gap: 1rem;
	border-radius: 8px;
	cursor: pointer;
	background: ${palette.Pearl};
	overflow: hidden;
	transition: all 200ms ease-out;
	&:hover {
		transform: translate(0, -0.25rem);
		box-shadow: 0px 0px 16px 0px ${palette.Pearl};
	}
`;

export const WrapperLeft = styled.div`
	display: flex;
	align-items: center;
`;

export const WrapperRight = styled.div`
	display: flex;
	justify-content: center;
	grid-gap: 0.5rem;
	flex-direction: column;
`;
