import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
	Container,
	Wrapper,
	WrapperSection,
	WrapperCard,
	WrapperPSE,
} from "./styles";
import * as palette from "../../assets/Colors";

import * as REGEX from "../../assets/REGEX";
import API from "../../API";

import { useFetchRSA } from "../../services/useFetchRSA";
import { useFetchDocumentTypes } from "../../services/useFetchDocumentTypes";
import { useFetchUserTypes } from "../../services/useFetchUserTypes";
import { useFetchBanks } from "../../services/useFetchBanks";

import {
	SELECTED_COUNTRY,
	SELECTED_STATE,
	SELECTED_CITY,
	SELECTED_BANK,
	SELECTED_DOCUMENT_TYPE,
	SELECTED_USER_TYPE,
} from "../../assets/STORAGE_KEYS";
import secureLocalStorage from "react-secure-storage";

import Input from "../../components/input/Input";
import Label from "../../components/label/Label";
import Button from "../../components/button/Button";
import RadioPaymentMethod from "../../components/radioPaymentMethod/RadioPaymentMethod";
import Dropdown from "../../components/dropdown/Dropdown";
import Image from "../../components/image/Image";

const Billing = () => {
	const [collection, setCollection] = useState({});
	const [publicIP, setPublicIP] = useState("");
	const [params] = useSearchParams();
	const { public_key } = useFetchRSA(params.get("entity_id"));
	const [lash, setLash] = useState(1);

	useEffect(() => {
		API.fetchPublicIP().then((ip) => {
			setPublicIP(ip);
		});

		API.getCollectionByID().then((collection) => {
			setCollection(collection);
		});

		getCountries();
	}, []);

	useEffect(() => {
		setIsValidExtra2(collection.extra2_name ? false : true);
		setIsValidExtra3(collection.extra3_name ? false : true);
	}, [collection]);

	// User information form logic

	const { documentTypes } = useFetchDocumentTypes();

	const [country, setCountry] = useState(undefined);
	const [countries, setCountries] = useState([]);

	const [state, setState] = useState(undefined);
	const [states, setStates] = useState([]);

	const [city, setCity] = useState(undefined);
	const [cities, setCities] = useState([]);

	const getCountries = async () => {
		const _countries = await API.fetchCountries();

		let array = [];
		await _countries.forEach((country) => {
			array.push({ value: country.id, label: country.name });
		});

		setCountries(array);
	};

	const getStates = async (e) => {
		if (e !== country) {
			setCountry(e);
			setState(undefined);

			setIsValidCountry(true);
			setIsValidState(false);
			setIsValidCity(false);

			secureLocalStorage.setItem(SELECTED_COUNTRY, e);
			secureLocalStorage.clear(SELECTED_STATE, SELECTED_CITY);
			secureLocalStorage.removeItem(SELECTED_STATE);
			secureLocalStorage.removeItem(SELECTED_CITY);

			const _states = await API.fetchStates(e.value);

			let array = [];
			await _states.forEach((state) => {
				array.push({ value: state.id, label: state.name });
			});
			setStates(array);
		}
	};

	const getCities = async (e) => {
		if (e !== state) {
			setState(e);
			setCity(undefined);

			setIsValidState(true);
			setIsValidCity(false);

			secureLocalStorage.setItem(SELECTED_STATE, e);
			secureLocalStorage.clear(SELECTED_CITY);

			const _cities = await API.fetchCities(country.value, e.value);

			let array = [];
			await _cities.forEach((city) => {
				array.push({ value: city.id, label: city.name });
			});
			setCities(array);
		}
	};

	const handleCity = (e) => {
		setCity(e);
		setIsValidCity(true);
		secureLocalStorage.setItem(SELECTED_CITY, e);
	};

	const [documentType, setDocumentType] = useState(undefined);

	const [isValidNames, setIsValidNames] = useState(false);
	const [isValidLastNames, setIsValidLastNames] = useState(false);
	const [isValidDocumentType, setIsValidDocumentType] = useState(false);
	const [isValidId, setIsValidId] = useState(false);
	const [isValidAddress, setIsValidAddress] = useState(false);
	const [isValidCountry, setIsValidCountry] = useState(false);
	const [isValidState, setIsValidState] = useState(false);
	const [isValidCity, setIsValidCity] = useState(false);
	const [isValidZipCode, setIsValidZipCode] = useState(false);
	const [isValidCellphone, setIsValidCellphone] = useState(false);
	const [isValidEmail, setIsValidEmail] = useState(false);
	const [isValidExtra2, setIsValidExtra2] = useState(false);
	const [isValidExtra3, setIsValidExtra3] = useState(false);

	const [paymentMethod, setPaymentMethod] = useState("");
	const [termsAndConditions, setTermsAndConditions] = useState(false);

	function handleNames(e) {
		e.target.value = REGEX.formatFullname(e.target.value);
		setIsValidNames(REGEX.isValidNames(e.target.value));
	}

	function handleLastNames(e) {
		e.target.value = REGEX.formatFullname(e.target.value);
		setIsValidLastNames(REGEX.isValidNames(e.target.value));
	}

	function handleDocumentType(e) {
		setDocumentType(e);
		setIsValidDocumentType(true);
	}

	function handleId(e) {
		e.target.value = REGEX.formatId(e.target.value);
		setIsValidId(REGEX.isValidId(e.target.value));
	}

	function handleAddress(e) {
		setIsValidAddress(/.{10,}/g.test(e.target.value));
	}

	function handleZipCode(e) {
		e.target.value = REGEX.formatZipCode(e.target.value);
		setIsValidZipCode(REGEX.isValidZipCode(e.target.value));
	}

	function handleCellphone(e) {
		e.target.value = REGEX.formatCellphone(e.target.value);
		setIsValidCellphone(REGEX.isValidCellphone(e.target.value));
	}

	function handleEmail(e) {
		e.target.value = REGEX.formatEmail(e.target.value);
		setIsValidEmail(REGEX.isValidEmail(e.target.value));
	}

	function handleExtra2(e) {
		setIsValidExtra2(/.{1,}/.test(e.target.value));
	}

	function handleExtra3(e) {
		setIsValidExtra3(/.{1,}/.test(e.target.value));
	}

	function handleTermsAndConditions(e) {
		setTermsAndConditions(e.target.checked);
	}

	function handleNext() {
		setLash(paymentMethod === "pse" ? 3 : 2);
	}

	// Payment by credit card logic

	const [card, setCard] = useState({
		number: "",
		expDate: "",
		ccv: "",
		franchise: "",
	});

	const [isValidAmountCard, setIsValidAmountCard] = useState(false);
	const [isValidFullname, setIsValidFullname] = useState(false);
	const [isValidCard, setIsValidCard] = useState(false);
	const [isValidCCV, setIsValidCCV] = useState(false);
	const [isValidExpDate, setIsValidExpDate] = useState(false);

	function handleAmountCard(e) {
		e.target.value = REGEX.formatAmount(e.target.value);
		setIsValidAmountCard(e.target.value.length > 2 ? true : false);
	}

	function handleFullname(e) {
		e.target.value = REGEX.formatFullname(e.target.value);
		setIsValidFullname(REGEX.isValidFullname(e.target.value));
	}

	function handleNumber(e) {
		e.target.value = REGEX.formatCardNumber(e.target.value);
		setCard({
			...card,
			number: e.target.value,
			franchise: REGEX.getCardFranchise(e.target.value),
		});
		setIsValidCard(
			REGEX.isValidCard(e.target.value) &&
				REGEX.CARD_NUMBER_LENGTH.test(e.target.value.replace(/\D/g, "")) &&
				REGEX.getCardFranchise(e.target.value)
				? true
				: false
		);
	}

	function handleExpDate(e) {
		e.target.value = REGEX.formatExpDate(card.expDate, e.target.value);
		setIsValidExpDate(REGEX.EXPDATE.test(e.target.value));
		setCard({ ...card, expDate: e.target.value });
	}

	function handleCCV(e) {
		e.target.value = REGEX.formatCCV(e.target.value);
		setCard({ ...card, ccv: e.target.value });
		setIsValidCCV(REGEX.CCV.test(e.target.value));
	}

	function collectDataCard() {
		const nombres = document.querySelector("#namesInput").value;
		const apellidos = document.querySelector("#lastNamesInput").value;
		const tipo_documento = documentType.value;
		const numero_documento = document.querySelector(
			"#documentNumberInput"
		).value;
		const direccion = document.querySelector("#addressInput").value;
		const codigo_postal = document.querySelector("#zipCodeInput").value;
		const telefono = document
			.querySelector("#cellphoneInput")
			.value.replace(/\D/g, "");
		const email = document.querySelector("#emailInput").value;
		const pais = country.value;
		const ciudad = city.value;
		const transaction_id = "ref1";
		const transaction_ip = publicIP;
		const creditcard_name = document.querySelector("#fullNameInput").value;
		const creditcard_number = API.encrypt(
			document.querySelector("#cardNumberInput").value.replace(/\D/g, ""),
			public_key
		);
		const creditcard_expirationdate = API.encrypt(
			document.querySelector("#expDateInput").value,
			public_key
		);
		const creditcard_securitycode = API.encrypt(
			document.querySelector("#ccvInput").value,
			public_key
		);
		const tipo_tc = REGEX.getCardFranchise(
			document.querySelector("#cardNumberInput").value.replace(/\D/g, "")
		);
		const total = parseInt(
			document.querySelector("#amountCardInput").value.replace(/\D/g, "")
		);
		API.getCollectionByID().then((collection) => {});
		const tax_percentage = collection.tax_percentage;
		const description = collection.description;
		const extra2 = collection.extra2_name;
		const extra3 = collection.extra3_name;
		fetchCardPay(
			nombres,
			apellidos,
			tipo_documento,
			numero_documento,
			direccion,
			codigo_postal,
			telefono,
			email,
			pais,
			ciudad,
			transaction_id,
			transaction_ip,
			creditcard_name,
			creditcard_number,
			creditcard_expirationdate,
			creditcard_securitycode,
			tipo_tc,
			total,
			tax_percentage,
			description,
			extra2,
			extra3
		);
	}

	async function fetchCardPay(
		nombres,
		apellidos,
		tipo_documento,
		numero_documento,
		direccion,
		codigo_postal,
		telefono,
		email,
		pais,
		ciudad,
		transaction_id,
		transaction_ip,
		creditcard_name,
		creditcard_number,
		creditcard_expirationdate,
		creditcard_securitycode,
		tipo_tc,
		total,
		tax_percentage,
		description,
		extra2,
		extra3
	) {
		await API.fetchCardPay(
			nombres,
			apellidos,
			tipo_documento,
			numero_documento,
			direccion,
			codigo_postal,
			telefono,
			email,
			pais,
			ciudad,
			transaction_id,
			transaction_ip,
			creditcard_name,
			creditcard_number,
			creditcard_expirationdate,
			creditcard_securitycode,
			tipo_tc,
			total,
			tax_percentage,
			description,
			extra2,
			extra3
		)
			.then((response) => response.json())
			.then((data) => {
				window.location.href = data.redirect_url;
			});
	}

	// Payment by PSE logic

	const { userTypes } = useFetchUserTypes();
	const { banks } = useFetchBanks();

	const [userType, setUserType] = useState(undefined);
	const [bank, setBank] = useState(undefined);

	const [isValidAmountPse, setIsValidAmountPse] = useState(false);
	const [isValidUser, setIsValidUser] = useState(
		secureLocalStorage.getItem(SELECTED_USER_TYPE) ? true : false
	);
	const [isValidBank, setIsValidBank] = useState(
		secureLocalStorage.getItem(SELECTED_BANK) ? true : false
	);

	function handleAmountPse(e) {
		e.target.value = REGEX.formatAmount(e.target.value);
		setIsValidAmountPse(e.target.value.length > 2 ? true : false);
	}

	function collectDataPSE() {
		const nombres = document.querySelector("#namesInput").value;
		const apellidos = document.querySelector("#lastNamesInput").value;
		const tipo_documento = 2;
		const numero_documento = document.querySelector(
			"#documentNumberInput"
		).value;
		const direccion = document.querySelector("#addressInput").value;
		const codigo_postal = document.querySelector("#zipCodeInput").value;
		const telefono = document
			.querySelector("#cellphoneInput")
			.value.replace(/\D/g, "");
		const email = document.querySelector("#emailInput").value;
		const typeuser = userType;
		const _bank = parseInt(bank.value);
		const pais = country.value;
		const ciudad = city.value;
		const transaction_id = "ref1";
		const transaction_ip = publicIP;
		const total = parseInt(
			document.querySelector("#amountPseInput").value.replace(/\D/g, "")
		);
		const tax_percentage = collection.tax_percentage;
		const description = collection.description;
		const extra2 = collection.extra2_name;
		const extra3 = collection.extra3_name;
		fetchPSEPay(
			nombres,
			apellidos,
			tipo_documento,
			numero_documento,
			direccion,
			codigo_postal,
			telefono,
			email,
			typeuser,
			_bank,
			pais,
			ciudad,
			transaction_id,
			transaction_ip,
			total,
			tax_percentage,
			description,
			extra2,
			extra3
		);
	}

	async function fetchPSEPay(
		nombres,
		apellidos,
		tipo_documento,
		numero_documento,
		direccion,
		codigo_postal,
		telefono,
		email,
		typeuser,
		bank,
		pais,
		ciudad,
		transaction_id,
		transaction_ip,
		total,
		tax_percentage,
		description,
		extra2,
		extra3
	) {
		await API.fetchPSEPay(
			nombres,
			apellidos,
			tipo_documento,
			numero_documento,
			direccion,
			codigo_postal,
			telefono,
			email,
			typeuser,
			bank,
			pais,
			ciudad,
			transaction_id,
			transaction_ip,
			total,
			tax_percentage,
			description,
			extra2,
			extra3
		)
			.then((response) => response.json())
			.then((data) => {
				window.location.href = data.link;
			});
	}

	return (
		<Container>
			<Wrapper style={{ display: lash === 1 ? "flex" : "none" }}>
				<form
					style={{ display: "flex", flexDirection: "column", gridGap: "1rem" }}
					autoComplete="on"
				>
					<Label
						type="Title"
						label="Información del pago"
						color={palette.Pearl}
					/>
					<WrapperSection>
						<Input
							placeholder="Nombres"
							onChange={handleNames}
							isValid={isValidNames}
							autoFocus={true}
							autoComplete="cc-given-name"
							id="namesInput"
						/>
						<Input
							placeholder="Apellidos"
							onChange={handleLastNames}
							isValid={isValidLastNames}
							autoComplete="cc-family-name"
							id="lastNamesInput"
						/>
					</WrapperSection>
					<WrapperSection>
						<Dropdown
							placeholder="Tipo de documento"
							options={documentTypes}
							storageKey={SELECTED_DOCUMENT_TYPE}
							externalValue={documentType}
							isValid={isValidDocumentType}
							onSelect={(e) => {
								handleDocumentType(e);
							}}
						/>
						<Input
							placeholder="Número de documento"
							onChange={handleId}
							isValid={isValidId}
							id="documentNumberInput"
						/>
					</WrapperSection>
					<WrapperSection>
						<Input
							placeholder="Dirección"
							onChange={handleAddress}
							isValid={isValidAddress}
							autoComplete="street-address"
							id="addressInput"
						/>
						<Input
							placeholder="Código postal"
							onChange={handleZipCode}
							isValid={isValidZipCode}
							autoComplete="postal-code"
							id="zipCodeInput"
						/>
					</WrapperSection>
					<WrapperSection>
						<Dropdown
							placeholder="País"
							searchPlaceholder="Buscar país..."
							options={countries}
							isSearchable
							storageKey={SELECTED_COUNTRY}
							externalValue={country}
							isValid
							onSelect={(e) => {
								getStates(e);
							}}
						/>
						<Dropdown
							placeholder="Departamento"
							searchPlaceholder="Buscar departamento..."
							options={states}
							storageKey={SELECTED_STATE}
							isSearchable
							isDisabled={!isValidCountry}
							isValid={isValidState}
							onSelect={(e) => {
								getCities(e);
							}}
						/>
						<Dropdown
							placeholder="Ciudad"
							searchPlaceholder="Buscar ciudad..."
							options={cities}
							storageKey={SELECTED_CITY}
							isSearchable
							isDisabled={!isValidState}
							isValid={isValidCity}
							onSelect={(e) => {
								handleCity(e);
							}}
						/>
					</WrapperSection>
					<WrapperSection>
						<Input
							placeholder="Número de celular"
							onChange={handleCellphone}
							isValid={isValidCellphone}
							autoComplete="tel-national"
							id="cellphoneInput"
						/>
						<Input
							placeholder="Correo electrónico"
							onChange={handleEmail}
							isValid={isValidEmail}
							autoComplete="email"
							id="emailInput"
						/>
					</WrapperSection>
					<Label
						type="Title"
						label="Referencia de pago"
						color={palette.Pearl}
					/>
					{collection.extra2_name || collection.extra3_name ? (
						<WrapperSection>
							{collection.extra2_name ? (
								<Input
									placeholder={collection.extra2_name}
									onChange={handleExtra2}
									isValid={isValidExtra2}
									id="extra2"
								/>
							) : null}
							{collection.extra3_name ? (
								<Input
									placeholder={collection.extra3_name}
									onChange={handleExtra3}
									isValid={isValidExtra3}
									id="extra3"
								/>
							) : null}
						</WrapperSection>
					) : null}
					<WrapperSection style={{ flexDirection: "column" }}>
						<Label type="Title" label="Método de pago" color={palette.Pearl} />
						<RadioPaymentMethod
							id="pseRadio"
							label="PSE"
							value={paymentMethod === "pse"}
							image="pse.png"
							onClick={() => {
								setPaymentMethod("pse");
							}}
						/>
						<RadioPaymentMethod
							id="ccRadio"
							label="Tarjeta de crédito"
							value={paymentMethod === "cc"}
							image="credit-cards.svg"
							onClick={() => {
								setPaymentMethod("cc");
							}}
						/>
					</WrapperSection>
					<WrapperSection>
						<label
							style={{
								color: palette.Pearl,
								fontFamily: "Montserrat, sans-serif",
								fontWeight: 500,
								fontSize: "13px",
							}}
						>
							<input
								type="checkbox"
								id="termsUseCheckbox"
								onClick={handleTermsAndConditions}
							/>
							¿Acepta los términos y condiciones y la política de tratamiento de
							datos?
						</label>
					</WrapperSection>
					<WrapperSection style={{ justifyContent: "flex-end" }}>
						<Button
							label="Siguiente"
							id="nextButton"
							onClick={handleNext}
							disabled={
								isValidNames &&
								isValidLastNames &&
								isValidDocumentType &&
								isValidId &&
								isValidAddress &&
								isValidCountry &&
								isValidState &&
								isValidCity &&
								isValidZipCode &&
								isValidCellphone &&
								isValidEmail &&
								isValidExtra2 &&
								isValidExtra3 &&
								paymentMethod &&
								termsAndConditions
									? false
									: true
							}
							iconRight="ArrowRight"
						/>
					</WrapperSection>
				</form>
			</Wrapper>
			<WrapperCard style={{ display: lash === 2 ? "flex" : "none" }}>
				<form
					style={{ display: "flex", flexDirection: "column", gridGap: "1rem" }}
					autoComplete="on"
				>
					<Label type="Title" label="Cantidad a pagar" color={palette.Pearl} />
					<Input
						placeholder="$ 0,00"
						onChange={handleAmountCard}
						isValid={isValidAmountCard}
						autoFocus={true}
						id="amountCardInput"
					/>
					<Label
						type="Title"
						label="Información de la tarjeta"
						color={palette.Pearl}
					/>
					<Input
						placeholder="Nombre completo del propietario"
						onChange={handleFullname}
						isValid={isValidFullname}
						autoComplete="cc-name"
						id="fullNameInput"
					/>
					<Input
						input="card"
						placeholder="Número de la tarjeta"
						onChange={handleNumber}
						isLuhnChecked={REGEX.isValidCard(card.number)}
						isFullInput={REGEX.CARD_NUMBER_LENGTH.test(
							card.number.replace(/\D/g, "")
						)}
						franchise={card.franchise}
						autoComplete="cc-number"
						id="cardNumberInput"
					/>
					<WrapperSection>
						<Input
							placeholder="Expiración MM/YY"
							onChange={handleExpDate}
							isValid={isValidExpDate}
							autoComplete="cc-exp"
							id="expDateInput"
						/>
						<Input
							input="secured"
							placeholder="CCV"
							onChange={handleCCV}
							isValid={isValidCCV}
							autoComplete="cc-csc"
							id="ccvInput"
						/>
					</WrapperSection>
				</form>
				<WrapperSection style={{ justifyContent: "space-between" }}>
					<Button
						label="Atrás"
						button="back"
						onClick={() => setLash(1)}
						iconLeft="ArrowLeft"
					/>
					<Button
						label="Completar pago"
						onClick={collectDataCard}
						disabled={
							isValidNames &&
							isValidLastNames &&
							isValidDocumentType &&
							isValidId &&
							isValidAddress &&
							isValidCountry &&
							isValidCity &&
							isValidZipCode &&
							isValidCellphone &&
							isValidEmail &&
							paymentMethod &&
							termsAndConditions &&
							isValidAmountCard &&
							isValidFullname &&
							isValidCard &&
							isValidCCV &&
							isValidExpDate
								? false
								: true
						}
						iconRight="Check"
					/>
				</WrapperSection>
			</WrapperCard>
			<WrapperPSE style={{ display: lash === 3 ? "flex" : "none" }}>
				<form
					style={{ display: "flex", flexDirection: "column", gridGap: "1rem" }}
					autoComplete="on"
				>
					<Label type="Title" label="Cantidad a pagar" color={palette.Pearl} />
					<Input
						placeholder="$ 0,00"
						onChange={handleAmountPse}
						isValid={isValidAmountPse}
						autoFocus={true}
						id="amountPseInput"
					/>
					<Label
						type="Title"
						label="Información bancaria"
						color={palette.Pearl}
					/>
					<Dropdown
						placeholder="Tipo de persona"
						options={userTypes}
						externalValue={userType}
						storageKey={SELECTED_USER_TYPE}
						isValid={isValidUser}
						onSelect={(e) => {
							setIsValidUser(true);
							setUserType(e);
						}}
					/>
					<Dropdown
						placeholder="Banco"
						options={banks}
						storageKey={SELECTED_BANK}
						externalValue={bank}
						isValid={isValidBank}
						onSelect={(e) => {
							setIsValidBank(true);
							setBank(e);
						}}
					/>
				</form>
				<WrapperSection style={{ justifyContent: "space-between" }}>
					<Button
						label="Atrás"
						button="back"
						onClick={() => setLash(1)}
						iconLeft="ArrowLeft"
					/>
					<Button
						label="Proceder al banco"
						onClick={collectDataPSE}
						disabled={
							isValidNames &&
							isValidLastNames &&
							isValidDocumentType &&
							isValidId &&
							isValidAddress &&
							isValidCountry &&
							isValidCity &&
							isValidZipCode &&
							isValidCellphone &&
							isValidEmail &&
							paymentMethod &&
							termsAndConditions &&
							isValidAmountPse &&
							isValidUser &&
							isValidBank
								? false
								: true
						}
						iconRight="ArrowRight"
					/>
				</WrapperSection>
			</WrapperPSE>
			<a href="https://linknovus.com/">
				<Image
					src={require("../../assets/images/Linknovus/NegativeMonocromatic.png")}
					width="192"
					height="48"
				/>
			</a>
		</Container>
	);
};

export default Billing;
