import React from "react";
import { useNavigate } from "react-router-dom";

import { ContainerElement, WrapperTop, WrapperBottom } from "./styles";
import Padding from "../padding/Padding";
import Label from "../label/Label";

const Entity = ({
	type,
	businessType,
	title,
	address,
	neighborhood,
	city,
	province,
	id,
	entityId,
	className,
}) => {
	const navigation = useNavigate();

	function onSelect() {
		navigation(`/billing?entity_id=${entityId}&collection_id=${id}`);
	}

	function getBusinessType() {
		switch (businessType) {
			case 1:
				return "Servicio público";
			case 2:
				return "Condominio";
			case 3:
				return "Transporte";
			case 4:
				return "Educación";
			case 5:
				return "Salud y medicina";
			case 6:
				return "Recarga móvil";
			case 7:
				return "Comercio";
			case 8:
				return "Evento";
			case 9:
				return "Servicio prepago";
			default:
				return;
		}
	}

	switch (type) {
		default:
			return (
				<ContainerElement id={id} onClick={onSelect} className={className}>
					<WrapperTop className="entityTop">
						<Label type="SmallTitle" label={title} />
						<Padding />
						<Label type="Tag" label={getBusinessType()} />
					</WrapperTop>
					<WrapperBottom className="entityBottom">
						<Label type="Subtitle" label={address + ", " + neighborhood} />
						<Label
							type="Subtitle"
							label={city + " - " + province}
							textAlign="right"
						/>
					</WrapperBottom>
				</ContainerElement>
			);
	}
};

export default Entity;
