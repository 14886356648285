import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 4rem 1rem 1rem 1rem;
	gap: 1rem;
	width: 100%;
	height: 100dvh;
	align-items: center;
	background: linear-gradient(0deg, #572bd6 0%, #61d0f3 100%);
	overflow-y: scroll;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 100%;
	transition: all 200ms ease-out;
	@media (min-width: 641px) {
		width: 640px;
	}
`;

export const WrapperCard = styled.div`
	grid-gap: 1rem;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	padding: 1rem;
	height: 100vh;
	width: 100%;
	transition: all 200ms ease-out;
	@media (min-width: 449px) {
		width: 448px;
	}
`;

export const WrapperPSE = styled.div`
	grid-gap: 1rem;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	padding: 1rem;
	height: 100vh;
	width: 100%;
	transition: all 200ms ease-out;
	@media (min-width: 449px) {
		width: 448px;
	}
`;

export const WrapperSection = styled.div`
	display: flex;
	grid-gap: 1rem;
	@media (max-width: 440px) {
		flex-direction: column;
	}
`;
