import React from "react";
import { Container } from "./styles";

const Image = (props) => {
	return (
		<Container src={props.src} width={props.width} height={props.height} />
	);
};

export default Image;
