import React, { useState, useEffect } from "react";

import API from "../../API";

import Input from "../../components/input/Input";
import Label from "../../components/label/Label";
import Image from "../../components/image/Image";
import Entity from "../../components/entity/Entity";

import {
	Container,
	Wrapper,
	WrapperSearch,
	WrapperResults,
	GridResults,
} from "./styles";

const Search = () => {
	const [collections, setCollections] = useState([]);

	useEffect(() => {
		const getBusinessTypes = async () => {
			/*const response = await API.fetchBusinessTypes(); commented due to not being in use*/
		};
		getBusinessTypes();
	}, []);

	async function onSearch(query) {
		await API.postSearch(query).then((response) => {
			setCollections(response);
		});
	}

	return (
		<Container>
			<WrapperSearch>
				<Wrapper>
					<Label
						type="Title"
						label="Paga lo que quieras, en cualquier momento y desde cualquier lugar."
					/>
					<Input
						id="searchInput"
						input="search"
						placeholder="¿Qué vas a pagar? Administración, servicios públicos, semestre universitario, suscripción..."
						onChange={(e) => onSearch(e.target.value)}
					/>
				</Wrapper>
			</WrapperSearch>
			<Wrapper>
				<WrapperResults>
					<Label type="Title" label="Resultados" />
					<GridResults>
						{collections.map((collection) => {
							const address = collection.collection_address.split(", ")[0];
							const neighborhood = collection.collection_address.split(", ")[1];
							const province = collection.collection_address.split(", ")[2];
							return (
								<Entity
									key={collection.id}
									id={collection.id}
									title={collection.commercial_name}
									address={address}
									neighborhood={neighborhood}
									city={collection.city_name}
									province={province}
									businessType={collection.business_type}
									entityId={collection.entity_id}
									className="Entity"
								/>
							);
						})}
					</GridResults>
				</WrapperResults>
			</Wrapper>
			<a href="https://linknovus.com/">
				<Image
					src={require("../../assets/images/Linknovus/NegativeColor.png")}
					width="192"
					height="48"
				/>
			</a>
		</Container>
	);
};

export default Search;
