import styled from "styled-components";
import * as palette from "../../assets/Colors";

export const InputDefault = styled.input`
	height: 3rem;
	width: 100%;
	border-radius: 0.5rem;
	padding: 0 0.75rem 0 0.75rem;
	align-items: center;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 13px;
	text-overflow: ellipsis;
	color: ${palette.Blueprint};
	border: none;
	cursor: pointer;
	outline: none;
	box-shadow: 0px 4px 16px 0px rgba(42, 85, 235, 0.25);
	transition: all 400ms ease-out;
	&:focus {
		box-shadow: 0px 0px 12px 4px #ffffff77;
	}
`;

export const InputCard = styled.input`
	height: 3rem;
	width: 100%;
	border-radius: 0.5rem;
	padding: 0 0.75rem 0 63px;
	align-items: center;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 13px;
	text-overflow: ellipsis;
	color: ${palette.Blueprint};
	border: none;
	outline: none;
	cursor: pointer;
	box-shadow: 0px 4px 16px 0px rgba(42, 85, 235, 0.1);
	transition: all 400ms ease-out;
	&:focus {
		box-shadow: 0px 0px 0px 2px ${palette.Zima};
	}
`;

export const InputSearch = styled.input`
	height: 4rem;
	width: 100%;
	padding: 0 4rem 0 1rem;
	border-radius: 1rem;
	align-items: center;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 15px;
	text-overflow: ellipsis;
	color: ${palette.Blueprint};
	border: none;
	outline: none;
	cursor: pointer;
	transition: all 200ms ease-out;
	&:hover {
		box-shadow: 0px 0px 16px 0px ${palette.Pearl};
	}
	&:focus {
		box-shadow: 0px 0px 16px 0px rgba(0, 134, 255, 0.5);
	}
`;

export const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;

export const WrapperIcon = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	height: 4rem;
	width: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
`;

export const WrapperIsValid = styled.div`
	position: absolute;
	top: 0;
	height: 3rem;
	width: 3rem;
	padding: 0.75rem;
	transition: all 400ms ease-out;
`;

export const WrapperFranchise = styled.div`
	position: absolute;
	left: 0;
	margin: 0.75rem;
	height: 1.5rem;
	width: 39px;
	overflow: hidden;
	border-radius: 0.25rem;
	align-items: center;
	justify-content: center;
	transition: all 400ms ease-out;
`;
