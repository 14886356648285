import { useState, useEffect, useCallback } from "react";
import { SELECTED_USER_TYPE } from "../assets/STORAGE_KEYS";

import secureLocalStorage from "react-secure-storage";

export const useFetchUserTypes = () => {
	const [userTypes, setUserTypes] = useState([]);
	const [currentUserType, setCurrentUserType] = useState(
		secureLocalStorage.getItem(SELECTED_USER_TYPE)
	);
	const [errorUserTypes, setErrorUserTypes] = useState(false);

	const fetchUserTypes = async () => {
		try {
			let array = [
				{ value: "person", label: "Persona natural" },
				{ value: "company", label: "Persona jurídica" },
			];
			setUserTypes(array);
			if (array[0] === null) {
				throw new Error("Array is empty.");
			}
		} catch (error) {
			setErrorUserTypes(true);
		}
	};

	const getCurrent = useCallback(async () => {
		try {
			const userType = secureLocalStorage.getItem(SELECTED_USER_TYPE);
			if (userType === null) {
				throw new Error("Item data is null.");
			} else {
				setCurrentUserType(userType);
				setErrorUserTypes(false);
			}
		} catch (error) {
			setErrorUserTypes(true);
		}
	}, []);

	useEffect(() => {
		fetchUserTypes();
		getCurrent();
	}, [getCurrent]);

	return { userTypes, currentUserType, errorUserTypes };
};
