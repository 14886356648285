import styled from "styled-components";
import * as palette from "../../assets/Colors";

export const Title = styled.h1`
	display: flex;
	font-size: 23px;
	line-height: 24px;
	color: ${palette.Pearl};
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	letter-spacing: -1px;
`;

export const SmallTitle = styled.h2`
	display: flex;
	font-size: 13px;
	line-height: 16px;
	color: ${palette.Blueprint};
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
`;

export const Subtitle = styled.h5`
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 11px;
	line-height: 16px;
	color: ${palette.Blueprint};
	opacity: 0.8;
`;

export const Tag = styled.h6`
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 11px;
	line-height: 12px;
	color: ${palette.Pearl};
	background: ${palette.Zima};
	border-radius: 4px;
	padding: 2px 4px;
	text-align: center;
`;

export const Labeled = styled.label`
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 13px;
	color: ${palette.Blueprint};
`;

export const Default = styled.h3`
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	font-size: 13px;
	color: ${palette.Blueprint};
	white-space: nowrap;
`;
