import React from "react";

import { Title, SmallTitle, Subtitle, Tag, Labeled, Default } from "./styles";

const Label = ({ type, label, textAlign, color, htmlFor }) => {
	switch (type) {
		case "Title":
			return <Title style={{ color: color }}>{label}</Title>;
		case "SmallTitle":
			return (
				<SmallTitle style={{ textAlign: textAlign, color: color }}>
					{label}
				</SmallTitle>
			);
		case "Subtitle":
			return <Subtitle style={{ textAlign: textAlign }}>{label}</Subtitle>;
		case "Tag":
			return <Tag>{label}</Tag>;
		case "Labeled":
			return (
				<Labeled style={{ color: color }} htmlFor={htmlFor}>
					{label}
				</Labeled>
			);
		default:
			return <Default>{label}</Default>;
	}
};

export default Label;
