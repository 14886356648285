import React from "react";

import { Container, Wrapper, Input, Image } from "./styles";
import Label from "../label/Label";
import Icon from "../../assets/Icon";
import * as palette from "../../assets/Colors";

const RadioPaymentMethod = ({ id, value, label, image, onClick }) => {
	return (
		<Container
			style={{
				background: value ? "rgba(217, 237, 255, 1)" : "#fff",
				boxShadow: value
					? `0px 0px 0px 2px ${palette.Zima}`
					: `0px 4px 16px 0px rgba(42, 85, 235, 0.25)`,
			}}
		>
			<Wrapper>
				<Icon
					name={value ? "RadioSelected" : "RadioUnselected"}
					size={24}
					color={palette.Zima}
				/>
				<Label label={label} />
			</Wrapper>
			<Image src={require(`../../assets/images/Payment/${image}`)} />
			<Input type="radio" id={id} value={value} onClick={onClick} />
		</Container>
	);
};

export default RadioPaymentMethod;
