import {
	AMEX_ID,
	DISCO_ID,
	DINERS_ID,
	MASTER_ID,
	JCB_ID,
	VISA_ID,
	CUP_ID,
} from "./Variables";

//Money amount validation RegEx
export const AMOUNT = /^\$\s\d{1,7}/;

//Money amount formatting RegEx
export function formatAmount(m) {
	return m
		.replace(/\D/g, "")
		.replace(/^0+(\d{0,})/g, "$1")
		.replace(/^(\d{7})\d{1,}/g, "$1")
		.replace(/^(\d{1,3})$/g, "$ $1")
		.replace(/^(\d{1,3})(\d{3})$/g, "$ $1.$2")
		.replace(/^(\d{1})(\d{3})(\d{3})$/g, "$ $1.$2.$3");
}

//Email validation RegEx
export const EMAIL =
	/^\w+([.-]?\w+)*@[a-z0-9-]+([.-]?[a-z0-9-]+)*(\.[a-z0-9]{2,4})+$/;

//Email validation function
export function isValidEmail(e) {
	return EMAIL.test(e);
}

export function formatEmail(e) {
	return e
		.toLowerCase()
		.replace(/[^\w-.@]/g, "") // Prevent from entering non alphanumeric characters, or characters that are NOT either AT's (@), dashes (-) or periods (.).
		.replace(/@{2,}/g, "@") // Prevent from entering AT's (@) right next to each other.
		.replace(/\.{2,}/g, ".") // Prevent from entering periods right next to each other.
		.replace(/^[@.-]+/g, "") // Prevent from entering emails that start with AT's, periods or dashes.
		.replace(/^([\w-.]+@)[.-]+(.*)/, "$1$2") // Prevent from entering dashes, underscores or periods right after the AT (@).
		.replace(/^([\w-.]+@[a-z0-9.-]*)_([a-z0-9.-]*)/, "$1$2") // Prevent from entering underscores (_) after the AT (@), as domains may never have underscores.
		.replace(/(-+\.|\.-+)/g, ".") // Prevent from entering dashes (-) right next to periods (.).
		.replace(/(\.+@|@\.+)/g, "@") // Prevent from entering periods (.) right next to an AT (@).
		.replace(/(-+@|@-+)/g, "@") // Prevent from entering dashes (-) right next to an AT (@).
		.replace(/(.+@.+)@/g, "$1"); // Prevent from entering more than one AT (@) in the whole string.
}

//Password validation RegEx's
export const HAS_SPACE = /\s+/;
export const HAS_DIGIT = /\d+/;
export const HAS_QUANTITY = /^.{8,}$/;
export const HAS_UPPERCASE = /[A-Z]+/;
export const HAS_LOWERCASE = /[a-z]+/;
export const HAS_SPECIAL_CHARACTER = /[@!¡¿?#$%&'*,+-_.~^`"{}()[\]|\\/]+/;

//Password validation function
export function isValidPassword(p) {
	return {
		hasSpace: HAS_SPACE.test(p),
		hasDigit: HAS_DIGIT.test(p),
		hasQuantity: HAS_QUANTITY.test(p),
		hasUppercase: HAS_UPPERCASE.test(p),
		hasLowercase: HAS_LOWERCASE.test(p),
		hasSpecialCharacter: HAS_SPECIAL_CHARACTER.test(p),
		isValid:
			!HAS_SPACE.test(p) ||
			(HAS_DIGIT.test(p) &&
				HAS_QUANTITY.test(p) &&
				HAS_UPPERCASE.test(p) &&
				HAS_LOWERCASE.test(p) &&
				HAS_SPECIAL_CHARACTER.test(p))
				? true
				: false,
	};
}

//Cellphone validation RegEx's
export const CELLPHONE_30 = /^30[01245]\d{7}$/;
export const CELLPHONE_31 = /^31\d{8}$/;
export const CELLPHONE_32 = /^32[0-4]\d{7}/;
export const CELLPHONE_35 = /^35[01]\d{7}/;

//Cellphone validation function
export function isValidCellphone(c) {
	let formatted = c.replace(
		/\D/g,
		"" // Clear all non-digits characters
	);

	return (
		CELLPHONE_30.test(formatted) ||
		CELLPHONE_31.test(formatted) ||
		CELLPHONE_32.test(formatted) ||
		CELLPHONE_35.test(formatted)
	);
}

//Cellphone formatter function
export function formatCellphone(unformatted) {
	let formatted = unformatted
		.replace(
			/\D/g,
			"" // Prevent entering non-digits characters
		)
		.replace(
			/^(\d{10})\d+$/g,
			"$1" // Prevent from entering more than 10 digits
		)
		.replace(
			/^[^3](\d*)$/g,
			"3$1" // Adding the prefix "3" before the rest of the numbers
		)
		.replace(
			/^3[^0125](\d*)$/g,
			"31$1" // Numbers that don't start with 30, 31, 32 or 35 are replaced with 31 followed by the rest of the number
		)
		.replace(
			/^(3\d{2})(\d{1,3})$/g,
			"$1 $2" // Auto-split first two groups of numbers with space
		)
		.replace(
			/^(3\d{2})(\d{3})(\d{1,4})$/g,
			"$1 $2 $3" // Auto-split last two groups of numbers with space
		);
	if (/^30\d{0,8}/.test(formatted)) {
		formatted.replace(/^30[^01245](\d{0,7})/, "300$1");
	}
	if (/^32\d{0,8}/.test(formatted)) {
		formatted.replace(/^32[^0-4](\d{0,7})/, "320$1");
	}
	if (/^35\d{0,8}/.test(formatted)) {
		formatted.replace(/^35[^0|1](\d{0,7})/, "350$1");
	}
	return formatted;
}

//Telephone validation RegEx
export const TELEPHONE = /^60[^0369][1-8]\d{6}$/;

//Telephone validation function
export function isValidTelephone(t) {
	return TELEPHONE.test(t);
}

//Telephone formatter function
export function formatTelephone(x) {
	return x
		.replace(
			/\D/g,
			"" // Prevent entering non-digits characters
		)
		.replace(
			/^(\d{10})\d+$/g,
			"$1" // Prevent from entering more than 10 digits
		)
		.replace(
			/^[^6](\d*)$/g,
			"6$1" // Adding the prefix "6" before the rest of the numbers
		)
		.replace(
			/^6[^0](\d*)$/g,
			"60$1" // Adding the prefix "0" after the "6" and before the rest of the numbers
		)
		.replace(
			/^60[^124578](\d*)$/g,
			"601$1" // Numbers that don't start with 601, 602, 604, 605, 607, 608 are replaced with 601 followed by the rest of the number
		)
		.replace(
			/^60(\d)[0|9](\d*)$/g,
			"60$11$2" // Additional filter for numbers which 4th digit is either 0 or 9, which are considered to be incorrect
		)
		.replace(
			/^(\d{3})(\d{1,7})$/g,
			"$1 - $2" // Auto-split number with spaces and dash
		);
}

//Zip Code validation RegEx
export const ZIPCODE = /[\d]{6}/;

//Zip Code validation function
export function isValidZipCode(x) {
	return ZIPCODE.test(x);
}

//Zip Code formatter function
export function formatZipCode(x) {
	return x
		.replace(
			/\D/g,
			"" // Prevent entering non-digits characters
		)
		.replace(
			/^(\d{6})\d+$/g,
			"$1" // Prevent from entering more than 6 digits
		);
}

//Names validation RegEx
export const NAMES =
	/^[A-ZÁÉÍÓÚ][a-zA-ZáéíóúÁÉÍÓÚ]{2,}(?: [A-ZÁÉÍÓÚ][a-zA-ZáéíóúÁÉÍÓÚ]*){1,2}$/;

//Name validation function
export function isValidNames(n) {
	return NAMES.test(n);
}

//Fullname validation RegEx
export const FULLNAME =
	/^[A-ZÁÉÍÓÚ][a-zA-ZáéíóúÁÉÍÓÚ]{2,}(?: [A-ZÁÉÍÓÚ][a-zA-ZáéíóúÁÉÍÓÚ]*){1,4}$/;

//Fullname validation function
export function isValidFullname(f) {
	return FULLNAME.test(f);
}

//Fullname formatter function
export function formatFullname(f) {
	const fn1 = f // Create an array that will store each separate word from fullname
		.replace(
			/[^A-Za-záÁéÉíÍóÓúÚ\s]/g,
			"" // Prevent entering non-alphabetic characters
		)
		.replace(/\s{2,}/g, " ") // Prevent entering double spaces
		.split(" "); // Separate fullname into individual words
	for (var i = 0; i < fn1.length; i++) {
		fn1[i] = fn1[i].charAt(0).toUpperCase() + fn1[i].slice(1); // Navigate each word in the array and capitalize only the first letter from each word
	}
	return fn1.join(" "); // Join each individual word into one single string
}

//ID validation RegEx
export const ID = /^\d{8,10}$/;

//ID validation function
export function isValidId(i) {
	return ID.test(i);
}

//ID formatter function
export function formatId(i) {
	return i
		.replace(
			/\D/g,
			"" // Prevent entering non-digits characters
		)
		.replace(
			/^(\d{10})\d+$/g,
			"$1" // Prevent from entering more than 6 digits
		);
}

//Card franchise validation RegEx
// export const JCB = /^35[0-9]{14}[0-9]*$/;
// export const VISA = /^4[0-9]{12}(?:[0-9]{3})?$/;
// export const AMERICAN_EXPRESS = /^3[47][0-9]{13}$/;
// export const DINERS_CLUB = /^3[0689][0-9]{12}[0-9]*$/;

// export const CHINA_UNION_PAY1 = /^62[0-9]{14}[0-9]*$/;
// export const CHINA_UNION_PAY2 = /^81[0-9]{14}[0-9]*$/;

// export const MASTERCARD1 = /^5[1-5][0-9]{14}$/;
// export const MASTERCARD2 = /^2[2-7][0-9]{14}$/;

// export const DISCOVER1 = /^6011[0-9]{12}[0-9]*$/;
// export const DISCOVER2 = /^62[24568][0-9]{13}[0-9]*$/;
// export const DISCOVER3 = /^6[45][0-9]{14}[0-9]*$/;

//Card franchise validation RegEx (tweaked for only 16-digit card numbers)
export const JAPAN_CREDIT_BUREAU = /^35\d{14}$/;
export const VISA = /^4\d{15}$/;
export const AMERICAN_EXPRESS = /^3[47]\d{13}$/;
export const DINERS_CLUB = /^3[0689]\d{14}$/;

export const CHINA_UNION_PAY = /^(62|81)\d{14}$/;

export const MASTERCARD = /^(5[1-5]|2[2-7])\d{14}$/;

export const DISCOVER1 = /^6011\d{12}$/;
export const DISCOVER2 = /^62[2-68]\d{13}$/;
export const DISCOVER3 = /^6[45]\d{14}$/;

//Card franchise getter function
export function getCardFranchise(n) {
	const c = n.replace(/\D/g, "");
	if (VISA.test(c)) {
		return VISA_ID;
	}
	if (AMERICAN_EXPRESS.test(c)) {
		return AMEX_ID;
	}
	if (MASTERCARD.test(c)) {
		return MASTER_ID;
	}
	if (DISCOVER1.test(c) || DISCOVER2.test(c) || DISCOVER3.test(c)) {
		return DISCO_ID;
	}
	if (DINERS_CLUB.test(c)) {
		return DINERS_ID;
	}
	if (JAPAN_CREDIT_BUREAU.test(c)) {
		return JCB_ID;
	}
	if (CHINA_UNION_PAY.test(c)) {
		return CUP_ID;
	}
	return undefined;
}

//Card number formatter function
export function formatCardNumber(n) {
	const x = n
		.replace(/\D/g, "")
		.replace(/^(\d{4})(\d{1,4})$/, "$1 $2")
		.replace(/^(\d{4})(\d{4})(\d{1,4})$/, "$1 $2 $3")
		.replace(/^(\d{4})(\d{4})(\d{4})(\d{1,4})$/, "$1 $2 $3 $4")
		.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})\d+$/, "$1 $2 $3 $4");
	return x;
}

//Card expiration date validation RegEx
export const EXPDATE = /^(0[1-9]|1[0-2])(\/)((?!2[0-3])(([2-9])([\d])))$/;

//Card expiration date formatter function
export function formatExpDate(before, after) {
	const formatted = after
		.replace(
			/[^\d/]|^[/]*$/g,
			"" // To allow only digits and slashes (10/20- > 10/20) -------
		)
		.replace(
			/^([1-9]\/|[2-9])$/g,
			"0$1/" // Auto add numbers to complete month + auto add slash (3 > 03/)
		)
		.replace(
			/^(0[1-9]|1[0-2])$/g,
			"$1/" // Auto add slash after month (11 > 11/)
		)
		.replace(
			/^([0-1])([3-9])$/g,
			"0$1/$2" // Auto split month from year (13 > 01/3)
		)
		.replace(
			/^(0?[1-9]|1[0-2])([0-9]{2})$/g,
			"$1/$2" // Auto split month from year (141 > 01/41)
		)
		.replace(
			/^([0]+)\/|[0]+$/g,
			"0" // Auto correct wrong months (0/ > 0 and 00 > 0)
		)
		.replace(
			/\/\//g,
			"/" // Prevent entering more than 1 slash (10// > 10)
		)
		.replace(
			/^(\d{2}\/\d{2})\d+$/g,
			"$1" // Prevent entering more than 2 year digits (10/203 > 10/20)
		);
	if (/^\d{2}\/$/.test(before) && /^\d{2}$/.test(after)) {
		return after;
	}
	return formatted;
}

//Card CCV validation RegEx
export const CCV = /^\d{3}$/;

//Card CCV formatter function
export function formatCCV(ccv) {
	return ccv.replace(/\D/g, "").replace(/^(\d{3})\d+$/, "$1");
}

//Card number length validation RegEx
export const CARD_NUMBER_LENGTH = /^\d{16}$/;

//Card number Luhn validation function
export function isValidCard(n) {
	const x = n.replace(/\D/g, "");

	let checksum = 0;
	let j = 1;

	for (let i = x.length - 1; i >= 0; i--) {
		let calc = 0;
		calc = Number(x.charAt(i)) * j;

		if (calc > 9) {
			checksum = checksum + 1;
			calc = calc - 10;
		}

		checksum = checksum + calc;

		if (j === 1) {
			j = 2;
		} else {
			j = 1;
		}
	}

	return checksum % 10 === 0;
}
