import styled from "styled-components";
import * as palette from "../../assets/Colors";

export const dropdownIndicatorStyles = {
	height: "3rem",
	width: "3rem",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	cursor: "pointer",
};

export const clearIndicatorStyles = {
	height: "3rem",
	width: "3rem",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
};

export const optionStyles = {
	lineHeight: "3rem",
	display: "flex",
	alignItems: "center",
	padding: "0 0.75rem",
	fontFamily: "Montserrat",
	fontSize: 13,
	whiteSpace: "nowrap",
	cursor: "pointer",
};

export const controlStyles = {
	borderRadius: 8,
	overflow: "hidden",
	height: "3rem",
	width: "100%",
	transition: "all 400ms ease-out",
};

export const menuListStyles = {
	marginTop: 8,
	borderRadius: 8,
	overflow: "hidden",
	boxShadow: "0px 4px 16px 0px rgba(42, 85, 235, 0.25)",
	background: "#FFF",
};

export const searchInputStyles = {
	width: "100em",
	paddingLeft: "0.75rem",
	fontFamily: "Montserrat",
	fontWeight: 500,
	fontSize: 13,
	lineHeight: "3rem",
	color: palette.Blueprint,
	outline: "none",
	border: "none",
};

export const Container = styled.div``;

export const Title = styled.label`
	font-size: 11px;
	color: ${palette.Blueprint};
	opacity: 0.65;
	line-height: 1rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	white-space: nowrap;
`;
