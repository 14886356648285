import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import secureLocalStorage from "react-secure-storage";

import {
	Container,
	optionStyles,
	controlStyles,
	menuListStyles,
	searchInputStyles,
	clearIndicatorStyles,
	dropdownIndicatorStyles,
	Title,
} from "./styles";
import Icon from "../../assets/Icon";
import Padding from "../padding/Padding";
import * as palette from "../../assets/Colors";

const sample_options = [
	{ value: "first_option", label: "Primera opción" },
	{ value: "second_option", label: "Segunda opción" },
	{ value: "third_option", label: "Tercera opción" },
];

const Dropdown = ({
	storageKey,
	onSelect,
	width,
	minWidth,
	title,
	placeholder,
	searchPlaceholder,
	options,
	isSearchable,
	isDisabled,
	externalValue,
	isValid,
}) => {
	const [value, setValue] = useState(externalValue);

	function selectHandler(e) {
		if (storageKey) {
			secureLocalStorage.setItem(storageKey, e);
		}
		onSelect(e);
	}

	useEffect(() => {
		const storageValue = secureLocalStorage.getItem(storageKey);
		if (storageValue) {
			setValue(storageValue);
		}
	}, [storageKey]);

	const DropdownIndicator = ({ innerProps, selectProps, hasValue }) => {
		return (
			<div {...innerProps} style={{ ...dropdownIndicatorStyles }}>
				<Icon
					name={selectProps.menuIsOpen ? "ArrowUp" : "ArrowDown"}
					color={hasValue ? palette.Zima : palette.Blueprint}
					size="24"
				/>
			</div>
		);
	};

	const ClearIndicator = (props) => {
		return (
			<div style={clearIndicatorStyles}>
				<components.ClearIndicator {...props}></components.ClearIndicator>
			</div>
		);
	};

	const IndicatorSeparator = () => {
		return <span />;
	};

	const Option = (props) => {
		return (
			<div
				style={{
					...optionStyles,
					background: props.isSelected ? palette.Zima : palette.Pearl,
					color: props.isSelected ? palette.Pearl : palette.Blueprint,
				}}
			>
				<components.Option {...props}></components.Option>
			</div>
		);
	};

	const Control = (props) => {
		return (
			<div
				style={{
					...controlStyles,
					background: props.hasValue
						? isValid
							? "rgba(217, 237, 255, 1)"
							: "rgba(255, 224, 228, 1)"
						: "#fff",
					opacity: props.isDisabled ? 0.65 : 1,
					boxShadow: props.selectProps.menuIsOpen
						? "0px 0px 12px 4px #ffffff77"
						: "0px 4px 16px 0px rgba(42, 85, 235, 0.25)",
				}}
			>
				<components.Control {...props} />
			</div>
		);
	};

	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				Sin resultados.
			</components.NoOptionsMessage>
		);
	};

	const MenuList = (props) => {
		return (
			<div style={menuListStyles}>
				<components.MenuList {...props}>{props.children}</components.MenuList>
			</div>
		);
	};

	const Input = (props) => {
		if (props.selectProps.menuIsOpen && props.selectProps.isSearchable) {
			return (
				<components.Input
					{...props}
					placeholder={searchPlaceholder}
					style={searchInputStyles}
				/>
			);
		}
	};

	return (
		<Container
			style={{
				flexDirection: "row",
				width: width ? width : "100%",
				minWidth: minWidth ? minWidth : 112,
			}}
		>
			{title !== undefined && title !== "" ? (
				<>
					<Title>{title}</Title>
					<Padding height={4} />
				</>
			) : null}
			<Select
				styles={{
					placeholder: (base) => ({
						...base,
						fontFamily: "Montserrat",
						fontSize: 13,
						color: palette.Blueprint,
						fontWeight: 500,
						lineHeight: "3rem",
						paddingLeft: "0.75rem",
						cursor: "pointer",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}),
					singleValue: (base) => ({
						...base,
						fontFamily: "Montserrat",
						fontSize: 13,
						color: palette.Blueprint,
						lineHeight: "3rem",
						fontWeight: 500,
						paddingLeft: "0.75rem",
						cursor: "pointer",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}),
					noOptionsMessage: (base) => ({
						...base,
						fontFamily: "Montserrat",
						fontWeight: 500,
						fontSize: 13,
						lineHeight: "3rem",
						color: palette.Blueprint,
						opacity: 0.75,
						cursor: "not-allowed",
					}),
				}}
				placeholder={placeholder}
				components={{
					Control,
					ClearIndicator,
					DropdownIndicator,
					IndicatorSeparator,
					Option,
					MenuList,
					Input,
					NoOptionsMessage,
				}}
				onChange={(e) => {
					selectHandler(e);
				}}
				closeMenuOnSelect={true}
				options={options ? options : sample_options}
				isSearchable={isSearchable ? isSearchable : false}
				blurInputOnSelect={true}
				defaultMenuIsOpen={false}
				value={value}
				isDisabled={isDisabled}
				unstyled
			/>
		</Container>
	);
};

export default Dropdown;
