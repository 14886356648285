// Internal ID for type of entity depending on their category

export const rc = "residential_complex";
export const i = "institution";
export const ps = "public_service";
export const ts = "transport_service";
export const hm = "health_medicine";
export const cs = "comerce_store";
export const ms = "mobile_service";

// Display strings for type of entities depending on their category

export const tag_rc = "Conjunto residencial";
export const tag_i = "Institución";
export const tag_ps = "Servicio público";
export const tag_ts = "Servicio de transporte";
export const tag_hm = "Salud y medicina";
export const tag_cs = "Comercios";
export const tag_ms = "Servicios móviles";

// Credit card franchise strings

export const AMEX_ID = "AMERICAN EXPRESS";
export const DISCO_ID = "DISCOVER";
export const DINERS_ID = "DINERS";
export const MASTER_ID = "MASTERCARD";
export const JCB_ID = "JCB";
export const MAESTRO_ID = "MAESTRO";
export const VISA_ID = "VISA";
export const CUP_ID = "UNIONPAY";
