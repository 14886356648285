import {
	GATEWAY_BANKS,
	PAYCENTER_GET_ACCOUNT,
	PAYCENTER_PSE_PAY,
	PAYCENTER_CARD_PAY,
	POST_SEARCH,
	GET_COUNTRIES,
	GET_STATES,
	GET_CITIES,
	GET_ENTITY_TYPES,
	GET_BUSINESS_TYPES,
	GET_DOCUMENT_TYPES,
	GET_PUBLIC_IP,
} from "./config";
import { JSEncrypt } from "jsencrypt";

const defaultConfigGet = {
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
};

const defaultConfigPost = {
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
};

const apiSettings = {
	postSearch: async (search_query) => {
		const body = { search_query };
		return await (
			await fetch(POST_SEARCH, {
				...defaultConfigPost,
				body: JSON.stringify(body),
			})
		).json();
	},

	fetchPayment: async (body) => {
		return await fetch(PAYCENTER_PSE_PAY, {
			...defaultConfigPost,
			body: JSON.stringify(body),
		});
	},

	fetchEntityTypes: async () => {
		return await (await fetch(GET_ENTITY_TYPES)).json();
	},

	fetchBusinessTypes: async () => {
		return await (await fetch(GET_BUSINESS_TYPES)).json();
	},

	getBanks: async () => {
		return await (
			await fetch(GATEWAY_BANKS, {
				...defaultConfigGet,
			})
		).json();
	},

	getAccount: async () => {
		return await fetch(PAYCENTER_GET_ACCOUNT, {
			...defaultConfigGet,
		});
	},

	fetchCardPay: async (
		nombres,
		apellidos,
		tipo_documento,
		numero_documento,
		direccion,
		codigo_postal,
		telefono,
		email,
		pais,
		ciudad,
		transaction_id,
		transaction_ip,
		creditcard_name,
		creditcard_number,
		creditcard_expirationdate,
		creditcard_securitycode,
		tipo_tc,
		total,
		tax_percentage,
		description,
		extra2,
		extra3
	) => {
		const body = {
			transaction: {
				currency_code: "COP",
				total,
				tax_percentage,
				description,
				extra2,
				extra3,
			},
			operation: {
				nombres,
				apellidos,
				tipo_documento,
				numero_documento,
				direccion,
				codigo_postal,
				telefono,
				email,
				pais,
				ciudad,
				transaction_id,
				transaction_ip,
				creditcard_name,
				creditcard_number,
				creditcard_expirationdate,
				creditcard_securitycode,
				tipo_tc,
			},
		};
		const urlParams = new URLSearchParams(window.location.search);
		const entity_id = urlParams.get("entity_id");
		return await fetch(PAYCENTER_CARD_PAY + entity_id, {
			...defaultConfigPost,
			body: JSON.stringify(body),
		});
	},

	fetchPSEPay: async (
		nombres,
		apellidos,
		tipo_documento,
		numero_documento,
		direccion,
		codigo_postal,
		telefono,
		email,
		typeuser,
		bank,
		pais,
		ciudad,
		transaction_id,
		transaction_ip,
		total,
		tax_percentage,
		description,
		extra2,
		extra3
	) => {
		const body = {
			transaction: {
				currency_code: "COP",
				total,
				tax_percentage,
				description,
				extra2,
				extra3,
			},
			operation: {
				nombres,
				apellidos,
				tipo_documento,
				numero_documento,
				direccion,
				codigo_postal,
				telefono,
				email,
				typeuser,
				bank,
				pais,
				ciudad,
				transaction_id,
				transaction_ip,
			},
		};
		const urlParams = new URLSearchParams(window.location.search);
		const entity_id = urlParams.get("entity_id");
		return await fetch(PAYCENTER_PSE_PAY + entity_id, {
			...defaultConfigPost,
			body: JSON.stringify(body),
		});
	},

	fetchPublicIP: async () => {
		try {
			const response = await (await fetch(GET_PUBLIC_IP)).json();
			return response.ip;
		} catch (error) {
			console.error("Error fetching public IP:", error);
		}
	},

	fetchCountries: async () => {
		return await (await fetch(GET_COUNTRIES)).json();
	},

	fetchStates: async (countryId) => {
		const endpoint = `${GET_STATES}${countryId}`;
		return await (await fetch(endpoint)).json();
	},

	fetchCities: async (countryId, stateId) => {
		const endpoint = `${GET_CITIES}${countryId}/${stateId}`;
		return await (await fetch(endpoint)).json();
	},

	fetchDocumentTypes: async () => {
		return await (await fetch(GET_DOCUMENT_TYPES)).json();
	},

	encrypt: (data, publicKey) => {
		const jsEncrypt = new JSEncrypt();
		jsEncrypt.setPublicKey(publicKey);
		return jsEncrypt.encrypt(data);
	},

	getCollectionByID: async () => {
		const urlParams = new URLSearchParams(window.location.search);
		const collection_id = urlParams.get("collection_id");
		return await (
			await fetch(POST_SEARCH + collection_id, {
				...defaultConfigGet,
			})
		).json();
	},
};

export default apiSettings;
