import { useState, useEffect, useCallback } from "react";
import { SELECTED_DOCUMENT_TYPE } from "../assets/STORAGE_KEYS";

import secureLocalStorage from "react-secure-storage";
import apiSettings from "../API";

export const useFetchDocumentTypes = () => {
	const [documentTypes, setDocumentTypes] = useState([]);
	const [currentDocumentType, setCurrentDocumentType] = useState(
		secureLocalStorage.getItem(SELECTED_DOCUMENT_TYPE)
	);
	const [errorDocumentTypes, setErrorDocumentTypes] = useState(false);

	const fetchDocumentTypes = async () => {
		try {
			const response = await apiSettings.fetchDocumentTypes();
			let array = [];
			await response.forEach((type) => {
				array.push({ value: type.id, label: type.name });
			});
			setDocumentTypes(array);
			if (array[0] === null) {
				throw new Error("Array is empty.");
			}
		} catch (error) {
			setErrorDocumentTypes(true);
		}
	};

	const getCurrent = useCallback(async () => {
		try {
			const documentType = secureLocalStorage.getItem(SELECTED_DOCUMENT_TYPE);
			if (documentType === null) {
				throw new Error("Item data is null.");
			} else {
				setCurrentDocumentType(documentType);
				setErrorDocumentTypes(false);
			}
		} catch (error) {
			setErrorDocumentTypes(true);
		}
	}, []);

	useEffect(() => {
		fetchDocumentTypes();
		getCurrent();
	}, [getCurrent]);

	return { documentTypes, currentDocumentType, errorDocumentTypes };
};
