import { useState, useEffect } from "react";
import { PAYCENTER_GET_ACCOUNT } from "../config";

export const useFetchRSA = (entity_id) => {
	const [public_key, setPublicKey] = useState("");

	async function consult(id) {
		const response = await fetch(`${PAYCENTER_GET_ACCOUNT}?entity_id=${id}`);
		return await response.json();
	}

	useEffect(() => {
		consult(entity_id).then((result) => {
			setPublicKey(result.rsa_public_key);
		});
	}, [entity_id]);

	return { public_key };
};
