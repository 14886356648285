const API_URL = "https://oito.co/";

const GATEWAY_URL = `${API_URL}gateway/`;
const PAYCENTER_URL = `${API_URL}paycenter/`;

// GATEWAY URL's
const GATEWAY_BANKS = `${GATEWAY_URL}banks`;
const GATEWAY_ACCOUNTS = `${GATEWAY_URL}account`;

// PAYCENTER URL's
const PAYCENTER_PSE_PAY = `${PAYCENTER_URL}pse?entity_id=`;
const PAYCENTER_CARD_PAY = `${PAYCENTER_URL}checkout?entity_id=`;
const PAYCENTER_GET_ACCOUNT = `${PAYCENTER_URL}account`;

/* GET URL'S */
const GET_COUNTRIES = `${API_URL}geo/country`;
const GET_STATES = `${API_URL}geo/region/`;
const GET_CITIES = `${API_URL}geo/city/`;
const GET_CITY = `${API_URL}geo/city/`;
const GET_ENTITY_TYPES = `${API_URL}entity/types`;
const GET_BUSINESS_TYPES = `${API_URL}entity/business_types`;

const GET_DOCUMENT_TYPES = `${GATEWAY_URL}document/types`;

const GET_PUBLIC_IP = "https://api.ipify.org?format=json";

// POST URL's
const POST_SEARCH = `${API_URL}collection/`;

export {
	GATEWAY_BANKS,
	GATEWAY_ACCOUNTS,
	PAYCENTER_CARD_PAY,
	PAYCENTER_GET_ACCOUNT,
	PAYCENTER_PSE_PAY,
	POST_SEARCH,
	GET_COUNTRIES,
	GET_STATES,
	GET_CITIES,
	GET_CITY,
	GET_DOCUMENT_TYPES,
	GET_PUBLIC_IP,
	GET_ENTITY_TYPES,
	GET_BUSINESS_TYPES,
};
