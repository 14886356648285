import styled from "styled-components";
import * as palette from "../../assets/Colors";

export const Default = styled.button`
	display: flex;
	grid-gap: 0.25rem;
	align-items: center;
	justify-content: center;
	height: 2.5rem;
	border-radius: 4px;
	border: none;
	background: ${palette.Pearl};
	font-family: "Catamaran", sans-serif;
	font-weight: 700;
	font-size: 15px;
	color: #572bd6;
	cursor: pointer;
	transition: all 400ms ease-out;
	&:hover {
		outline: none;
		box-shadow: 0px 0px 8px 0px ${palette.Pearl};
		grid-gap: 0.5rem;
		opacity: 0.5;
	}
`;
export const Back = styled.button`
	display: flex;
	grid-gap: 0.25rem;
	align-items: center;
	justify-content: center;
	height: 2.5rem;
	border-radius: 4px;
	border: none;
	background: rgba(0, 0, 0, 0);
	font-family: "Catamaran", sans-serif;
	font-weight: 700;
	font-size: 15px;
	color: ${palette.Pearl};
	cursor: pointer;
	transition: all 400ms ease-out;
	&:hover {
		outline: none;
		background: rgba(255, 255, 255, 0.25);
		grid-gap: 0.5rem;
		opacity: 0.5;
	}
`;

export const Container = styled.img`
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	cursor: pointer;
	appearance: none;
	background-color: inherit;
	transition: transform 0.7s ease-in-out;
`;
