import styled from "styled-components";
import * as palette from "../../assets/Colors";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
	background: ${palette.Blueprint};
`;

export const WrapperSearch = styled.div`
	display: flex;
	padding-top: 1rem;
	width: 100%;
	justify-content: center;
	background: ${palette.Zima};
	border-radius: 0 0 24px 24px;
`;

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
	flex-grow: 1;
	max-width: 1024px;
	min-width: 0px;
	padding: 0 1rem 1rem;
`;

export const GridCategories = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr 1fr;
	@media (min-width: 1069px) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	@media (max-width: 676px) {
		grid-template-columns: 1fr 1fr;
	}
`;

export const WrapperCategories = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 1rem;
`;

export const WrapperResults = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	grid-gap: 1rem;
	padding: 1rem 0 1rem 0;
`;

export const GridResults = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr;
	@media (min-width: 1069px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	@media (max-width: 676px) {
		grid-template-columns: 1fr;
	}
`;
