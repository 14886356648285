import React from "react";

import { Default, Container, Back } from "./styles";
import * as palette from "../../assets/Colors";
import Icon from "../../assets/Icon";

const Button = ({
	button,
	onClick,
	id,
	href,
	style,
	onSubmit,
	label,
	disabled,
	alt,
	role,
	src,
	width,
	iconLeft,
	iconRight,
}) => {
	switch (button) {
		case "image":
			return (
				<Container
					style={{ width: width, height: "auto", style }}
					src={src}
					onClick={onClick}
					role={role}
					alt={alt}
				/>
			);
		case "back":
			return (
				<Back
					style={{
						opacity: disabled ? 0.5 : 1,
						cursor: disabled ? "not-allowed" : "pointer",
						paddingLeft: iconLeft ? "0.5rem" : "0.75rem",
						paddingRight: iconRight ? "0.5rem" : "0.75rem",
					}}
					type="button"
					onClick={onClick}
					id={id}
					href={href}
					onSubmit={onSubmit}
					disabled={disabled}
				>
					{iconLeft ? (
						<Icon name={iconLeft} color={palette.Pearl} size={24} />
					) : null}
					{label}
					{iconRight ? (
						<Icon name={iconRight} color={palette.Pearl} size={24} />
					) : null}
				</Back>
			);
		default:
			return (
				<Default
					style={{
						opacity: disabled ? 0.5 : 1,
						cursor: disabled ? "not-allowed" : "pointer",
						paddingLeft: iconLeft ? "0.5rem" : "0.75rem",
						paddingRight: iconRight ? "0.5rem" : "0.75rem",
					}}
					type="button"
					onClick={onClick}
					id={id}
					href={href}
					onSubmit={onSubmit}
					disabled={disabled}
				>
					{iconLeft ? <Icon name={iconLeft} color="#572bd6" size={24} /> : null}
					{label}
					{iconRight ? (
						<Icon name={iconRight} color="#572bd6" size={24} />
					) : null}
				</Default>
			);
	}
};

export default Button;
