import { useState, useEffect, useCallback } from "react";
import { SELECTED_BANK } from "../assets/STORAGE_KEYS";

import secureLocalStorage from "react-secure-storage";
import apiSettings from "../API";

export const useFetchBanks = () => {
	const [banks, setBanks] = useState([]);
	const [currentBank, setCurrentBank] = useState({});
	const [errorBanks, setErrorBanks] = useState(false);

	const fetchBanks = async () => {
		try {
			const response = await apiSettings.getBanks();
			let array = response;
			if (array[0]) {
				let x = [];
				array.forEach((bank) => {
					x.push({ value: bank.code, label: bank.name });
				});
				x.shift();
				setBanks(x);
			} else {
				throw new Error("Array is empty.");
			}
		} catch (error) {
			setErrorBanks(true);
		}
	};
	const getCurrent = useCallback(async () => {
		try {
			setCurrentBank(secureLocalStorage.getItem(SELECTED_BANK));
			if (currentBank === null) {
				throw new Error("Item data is null.");
			} else {
				setErrorBanks(false);
			}
		} catch (error) {
			setErrorBanks(true);
		}
	}, [currentBank]);

	useEffect(() => {
		getCurrent();
		fetchBanks();
	}, [getCurrent]);

	return { banks, currentBank, errorBanks };
};
